<template>
  <section>
    <b-row fluid>
      <b-col lg="12" cols="12" sm="12">
        <b-col cols="12">
          <b-card-actions
              class="rezervasyon-card"
              ref="first-card"
              @refresh="refreshStop('first-card')"
              v-if="!isNewReservastion"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Rezervasyon Listesi</h4>
                  <b-button
                      variant="flat-primary"
                      class="text-white float-right bg-primary bg-lighten-2 card-right-button"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                      @click="isNewReservastion = true"
                  >
                    Yeni Rezervasyon
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <b-tabs class="mt-3" v-model="activeTab"
                    :style="{marginTop : width < 300 ? '50%': width < 370 ? '35%': width < 439 ? '25%!important':''}"
            >
              <b-tab :title="item.salonAdi" v-for="item in salonlar" :key="item.salonID"
                     @click="salonRezervasyonGet(item.salonID)"
              >
                <div class="d-flex justify-content-between flex-wrap table-top">
                  <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                    <b-input-group
                        size="sm"
                        prepend="Gösterilecek kayıt sayısı"
                        class="unPrint"
                    >
                      <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          @change="perPageChange($event,item.salonID)"
                          size="sm"
                      >
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                  <b-input-group size="sm" class="search-bar">
                    <b-input-group-prepend>
                      <b-button
                          variant="outline-primary"
                          size="sm"
                          disabled
                          class="search-input mb-0"
                      >
                        Ara
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input id="filterInput" v-model="filter" type="search" autocomplete="off"/>
                  </b-input-group>
                </div>
                <hr/>
                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative my-table mt-1"
                    :per-page="perPage"
                    :items="rezervasyonList"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="go"
                >
                  <template #cell(cariUnvan)="data">
                    <strong>
                      {{ data.item.cariUnvan }}
                    </strong>
                  </template>
                  <template #cell(salonAdi)="data">
                    <p class="m-0">
                      {{ data.item.salonAdi }}
                    </p>
                  </template>
                  <template #cell(girisSaat)="data">
                    {{ data.item.girisSaat }}
                    <!--                    <flat-pickr-->
                    <!--                        v-model="data.item.girisSaat"-->
                    <!--                        class="form-control"-->
                    <!--                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"-->
                    <!--                    />-->
                  </template>
                  <template #cell(cikisSaat)="data">
                    {{ data.item.cikisSaat }}
                  </template>
                  <template #cell(tarifeID)="data">
                    {{ data.item.tarifeID == 1 ? 'Öğretmen' : data.item.tarifeID = 2 ? 'Kamu' : 'Sivil' }}
                  </template>
                  <template #cell(rezervasyonNotu)="data">
                    {{ data.item.rezervasyonNotu }}
                  </template>
                  <template #cell(toplamTutar)="data">
                    <p class="m-0"> {{
                        Number(data.item.toplamTutar)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </p>
                  </template>
                  <template #cell(rezervasyonID)="data">
                    <b-button
                        v-b-modal.rez-update
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-primary"
                        class="btn-icon rounded-circle"
                        @click="updateStepOne(data.item)"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="itemDelete(data.item.rezervasyonID)"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </template>
                </b-table>
                <hr/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ totalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="currentPage"
                        @change="PageChange($event,item.salonID)"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card-actions>
          <yeni-rezervasyon v-if="isNewReservastion" :show="isNewReservastion" @dataReturned="handleDataReturned"/>
        </b-col>
      </b-col>
    </b-row>
    <b-modal
        id="rez-update"
        ok-only
        ok-title="Güncelle"
        cancel-title="Vazgeç"
        centered
        size="lg"
        title="Rezervasyon Bilgilerini Düzenle"
        @ok="rezUpdate()"
        :ok-disabled="editRez.cariKartID == '' || editRez.salonID == ''|| editRez.tarifeID == ''|| editRez.girisSaat ==''|| editRez.cikisSaat == ''"
    >
      <b-row>
        <!--        <b-col cols="12">-->
        <!--          <b-form-group-->
        <!--              label="Cari Kart"-->
        <!--              label-for="basicInput"-->
        <!--          >-->
        <!--            <v-select-->
        <!--                v-model="editRez.cariKartID"-->
        <!--                :options="cariKartlar"-->
        <!--                label="firmaUnvan"-->
        <!--                input-id="invoice-data-client"-->
        <!--                :clearable="false"-->
        <!--                @search="(search, loading) => { loading(true)-->
        <!--                         $store.dispatch('cariCardlar', {-->
        <!--                            uzunluk: 20,-->
        <!--                            baslangic: 0,-->
        <!--                            cariGrupID: 0,-->
        <!--                            searchKey: search,-->
        <!--                          }).then(() => loading(false))-->
        <!--                        }"-->
        <!--            >-->
        <!--              <template #no-options>-->
        <!--                <p>Sonuç Bulunamadı</p>-->
        <!--              </template>-->
        <!--              <template #list-header>-->
        <!--                <li-->
        <!--                    v-b-modal.new-cari-->
        <!--                    class="add-new-client-header d-flex align-items-center my-50"-->
        <!--                >-->
        <!--                  <feather-icon-->
        <!--                      icon="PlusIcon"-->
        <!--                      size="16"-->
        <!--                  />-->
        <!--                  <span class="align-middle ml-25">Yeni Cari Kart</span>-->
        <!--                </li>-->
        <!--              </template>-->
        <!--            </v-select>-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <!--        <b-col cols="6">-->
        <!--          <b-form-group-->
        <!--              label="Tarife"-->
        <!--              label-for="basicInput"-->
        <!--          >-->
        <!--            <v-select-->
        <!--                v-model="editRez.tarifeID"-->
        <!--                :options="tarifeler"-->
        <!--                label="text"-->
        <!--                input-id="invoice-data-client"-->
        <!--                :clearable="false"-->
        <!--            >-->
        <!--              <template #no-options>-->
        <!--                <p>Sonuç Bulunamadı</p>-->
        <!--              </template>-->
        <!--            </v-select>-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <b-col lg="6" md="12" sm="12" cols="12">
          <b-form-group
              label="Salon"
              label-for="basicInput"
          >
            <v-select
                v-model="editRez.salonID"
                :options="salonlar"
                label="salonAdi"
                input-id="invoice-data-client"
                :clearable="false"
            >
              <template #no-options>
                <p>Sonuç Bulunamadı</p>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="12" sm="12" cols="12">
          <b-form-group
              label="Kira Tutarı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                autocomplete="off"
                v-model="editRez.kiraTutari"
                :step="priceStep"
            />
          </b-form-group>
        </b-col>
        <!--        <b-col cols="3">-->
        <!--          <b-form-group-->
        <!--              label="Başlangıç Tarihi ve Saati"-->
        <!--              label-for="basicInput"-->
        <!--          >-->
        <!--            <flat-pickr-->
        <!--                v-model="editRez.girisSaat"-->
        <!--                class="form-control"-->
        <!--                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <!--        <b-col cols="3">-->
        <!--          <b-form-group-->
        <!--              label="Bitiş Tarihi ve Saati"-->
        <!--              label-for="basicInput"-->
        <!--          >-->
        <!--            <flat-pickr-->
        <!--                v-model="editRez.cikisSaat"-->
        <!--                class="form-control"-->
        <!--                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BTab,
  BTabs,
  BSidebar,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem, VBToggle,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import _ from 'lodash'
import router from '@/router'
import vSelect from 'vue-select'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import YeniRezervasyon from './yeniRezervasyon.vue'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import { useWindowSize } from '@vueuse/core'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    const json = require('@/assets/turkey-cities.json').data
    const ilceler = []
    return {
      json,
      ilceler,
      width,
    }
  },
  components: {
    flatPickr,
    BTab,
    BTabs,
    YeniRezervasyon,
    FullCalendar,
    dayGridPlugin,
    interactionPlugin,
    timeGridPlugin,
    listPlugin,
    vSelect,
    BSidebar,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      activeTab: 0,
      rezervasyonID: '',
      tarifeler: [
        {
          value: '1',
          text: 'Öğretmen',
        }, {
          value: '2',
          text: 'Kamu',
        }, {
          value: '3',
          text: 'Sivil',
        },
      ],
      editRez: {
        salonID: '',
        tarifeID: '',
        kiraTutari: '',
        girisSaat: '',
        cikisSaat: '',
        rezervasyonNotu: '',
        odemeDurum: '',
        cariKartID: '',
      },
      degismeyenData: [],
      isNewReservastion: false,
      grupName: '',
      grupContent: '',
      grupTuruOptions: [
        {
          value: '1',
          text: 'Müşteriler',
        }, {
          value: '2',
          text: 'Tedarikçiler',
        }, {
          value: '3',
          text: 'Personeller',
        }, {
          value: '4',
          text: 'Diğer',
        },
      ],
      grupTuru: {
        value: '1',
        text: 'Müşteriler',
      },
      addCari: {
        cariGrupID: '',
        cariCardID: '',
        adi: '',
        soyAdi: '',
        adres: '',
        ePosta: '',
        telefon: '',
        firmaUnvan: '',
        vergiNumarasi: '',
        vergiDairesi: '',
        il: '',
        ilce: '',
        cariNot: '',
        banka: '',
        ibann: '',
      },
      newRez: {
        cariKart: {},
      },
      fields: [
        {
          key: 'cariUnvan',
          label: 'Cari Unvan',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'salonAdi',
          label: 'Salon',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'girisSaat',
          label: 'Giriş Saati',
          thClass: ['text-center'],
          tdClass: ['text-center'],
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'cikisSaat',
          label: 'Çıkış Saati',
          thClass: ['text-center'],
          tdClass: ['text-center'],
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'tarifeID',
          label: 'Tarife',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'rezervasyonNotu',
          label: 'Not',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'toplamTutar',
          label: 'Toplam Tutar',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'rezervasyonID',
          label: '',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '5%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
      ],
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      toplamKayit: 1,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    ilkGorunenSalonID() {
      return this.salonlar[this.activeTab]?.salonID
    },
    cariKartlar() {
      return Object.values(store.getters.CARI_CARD_GET)
    },
    salonlar() {
      return Object.values(store.getters.SALON_LISTESI_GET)
    },
    rezervasyonList() {
      return _.orderBy(Object.values(store.getters.REZERVASYON_LIST_GET), 'rezervasyonID', 'desc')
    },
  },
  methods: {
    rezUpdate() {
      var guncellenenKeyler = {}
      Object.entries(this.degismeyenData)
          .forEach(entry => {
            const [key, value] = entry
            if (key != 'cariKartID' && key != 'salonID' && key != 'tarifeID') {
              if (this.editRez[key] && this.editRez[key] != value) {
                guncellenenKeyler[key] = this.editRez[key]
              }
            }
          })
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'salon')
      fd.append('methodName', 'salonRezervasyonDuzenle')
      fd.append('rezervasyonID', this.rezervasyonID)
      fd.append('updateList[cariKartID]', this.editRez.cariKartID.cariKartID)
      fd.append('updateList[salonID]', this.editRez.salonID.salonID)
      fd.append('updateList[tarifeID]', this.editRez.tarifeID.value)
      Object.entries(guncellenenKeyler)
          .forEach(entry => {
            const [key, value] = entry
            fd.append(`updateList[${key}]`, value)
          })
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              this.salonRezervasyonGet(this.ilkGorunenSalonID)
              this.rezervasyonID = ''
              this.$bvModal.hide('rez-update')
              store.commit('setToken', res.data.userToken)
              const mesaj = store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
              this.editReset()
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            store.dispatch('cariGrup')
            store.dispatch('cariCardlar', {
              uzunluk: 20,
              baslangic: 0,
              cariGrupID: 0,
            })
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    updateStepOne(item) {
      this.rezervasyonID = item.rezervasyonID
      this.editRez.salonID = this.salonlar.find(x => x.salonID == item.salonID)
      this.editRez.tarifeID = item.tarifeID == 1 ? {
        value: '1',
        text: 'Öğretmen',
      } : item.tarifeID == 2 ? {
        value: '2',
        text: 'Kamu',
      } : item.tarifeID == 3 ? {
        value: '3',
        text: 'Sivil',
      } : item.tarifeID == 'Öğretmen' ? {
        value: '1',
        text: 'Öğretmen',
      } : item.tarifeID == 'Kamu' ? {
        value: '2',
        text: 'Kamu',
      } : item.tarifeID == 'Sivil' ? {
        value: '3',
        text: 'Sivil',
      } : ''
      this.editRez.kiraTutari = item.kiraTutari
      this.editRez.girisSaat = item.girisSaat
      this.editRez.cikisSaat = item.cikisSaat
      this.editRez.rezervasyonNotu = item.rezervasyonNotu
      this.$store
          .dispatch('cariDetay', {
            cariKartID: item.cariKartID,
          })
          .then(res => this.editRez.cariKartID = { ...res.data.cariData })
      this.degismeyenData = item
    },
    salonRezervasyonGet(salonID) {
      store.dispatch('rezervasyonListesi', {
        uzunluk: this.perPage,
        baslangic: 0,
        salonID,
      })
          .then(res => this.totalRows = res.toplamKayit)
    },
    go(item) {
      router.push({
        name: 'rezervasyonDetay',
        params: { id: item.rezervasyonID },
      })
    },
    handleDataReturned(data) {
      data == false ? this.isNewReservastion = false : true
    },
    itemDelete(id) {
      this.$swal({
        title: 'Rezervasyonu Sil',
        text: 'Bu işlem geri alınamaz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              store.dispatch('rezervasyonRemove', id)
                  .then((res, position = 'bottom-right') => {
                    if (res.result.status == 200) {
                      store.dispatch('rezervasyonListesi', {
                        uzunluk: this.perPage,
                        baslangic: 0,
                      })
                          .then(res => this.totalRows = res.toplamKayit)
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Başarılı',
                          text: res.result.errMsg,
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      }, { position })
                    } else {
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    }
                  })
                  .catch(err => {
                    const mesaj = store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            }
          })
    },
    perPageChange(newVal, salonID = this.ilkGorunenSalonID) {
      const veri = this.currentPage * newVal - newVal
      this.$store
          .dispatch('rezervasyonListesi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.filter,
            salonID,
          })
          .then(res => {
            this.toplamKayit = res.liste?.length
            this.totalRows = res?.toplamKayit
          })
    },
    PageChange(newVal, salonID = this.ilkGorunenSalonID) {
      const veri = newVal * this.perPage - this.perPage
      this.$store
          .dispatch('rezervasyonListesi', {
            uzunluk: this.perPage,
            baslangic: veri,
            searchKey: this.filter,
            salonID,
          })
          .then(res => {
            this.toplamKayit = res.liste?.length
            this.totalRows = res?.toplamKayit
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    newCariGrupHidden() {
      this.addCari.cariGrupID = ''
      this.$refs.newCariGrup.hide()
      this.$bvModal.show('new-cari')
    },
    editReset() {
      this.editRez.salonID = ''
      this.editRez.salonID = ''
      this.editRez.tarifeID = ''
      this.editRez.kiraTutari = ''
      this.editRez.girisSaat = ''
      this.editRez.cikisSaat = ''
      this.editRez.rezervasyonNotu = ''
      this.editRez.odemeDurum = ''
      this.editRez.cariKartID = ''
    },
  },
  watch: {
    activeTab() {
      this.currentPage = 1
      this.perPage = 10
      this.filter = ''
    },
    filter(newVal) {
      this.$store
          .dispatch('rezervasyonListesi', {
            uzunluk: this.perPage,
            baslangic: 0,
            searchKey: newVal,
            salonID: this.ilkGorunenSalonID,
          })
          .then(res => {
            this.totalRows = res?.toplamKayit
            this.toplamKayit = res.liste?.length
          })
    },
    ilkGorunenSalonID(newVal) {
      this.salonRezervasyonGet(newVal)
    },
  },
  created() {
    store.dispatch('salonListesi')
    this.salonRezervasyonGet(this.ilkGorunenSalonID)
  },
  beforeDestroy() {
    console.clear()
    store.commit('REZERVASYON_LIST_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
}
</script>
<style>
.my-table .table th, .table td {
  padding: 0.2rem 0.6rem !important;
}

.rezervasyon-card .card-header {
  display: none !important;
}
</style>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
#filterInput:focus {
  border-color: #c3baba !important;
}

.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.my-table .table thead th, table tbody td {
  padding: 0.4rem !important;
}

.table-top {
  margin-top: 1.5% !important;
}

.search-bar {
  width: 20% !important;
}

.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.cari-group-edit .card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
  background-color: #F48484 !important;
}

.search-input {
  border-color: #c3baba !important;
}

#filterInput:focus {
  border-color: #c3baba !important;
}

h4 {
  margin-bottom: 0 !important;
  padding: 6px;
}

.my-table button {
  border: none !important;
}

@media (max-width: 1000px) {
  .search-bar {
    width: 35% !important;
  }
}

@media (max-width: 768px) {
  .search-bar {
    width: 50% !important;
  }

  .search-bar .search-input {
    height: 30px;
  }
}

@media (max-width: 400px) {
  .search-bar {
    width: 100% !important;
  }
}


</style>
